<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      :rules="dataRule"
      label-width="120px"
      @keyup.enter.native="dataFormSubmit()"
    >
      <el-form-item label="冷柜名字" prop="freezerId">
        <el-select
          v-model="dataForm.freezerId"
          :disabled="disabled"
          clearable
          filterable
        >
          <el-option
            v-for="(item, index) in freezerList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="轮播图片" prop="imageUrl">
        <el-upload
          v-model="dataForm.imageUrl"
          class="avatar-uploader"
          action="#"
          :http-request="fileUpload"
          :show-file-list="false"
          :before-upload="beforeUpload"
        >
          <img
            v-if="dataForm.imageUrl"
            :src="dataForm.imageUrl"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon" />
        </el-upload>
      </el-form-item>
      <el-form-item label="轮播图名字" prop="name">
        <el-input
          v-model="dataForm.name"
          :disabled="disabled"
          placeholder="轮播图名字"
        ></el-input>
      </el-form-item>
      <el-form-item label="轮播图链接" prop="imageUrl">
        <el-input
          v-model="dataForm.imageUrl"
          :disabled="disabled"
          placeholder="轮播图链接"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否使用" prop="isShow">
        <el-tooltip class="item" effect="dark" placement="top">
          <i class="el-icon-question"> </i>

          <div style="width: 200px" slot="content">禁用后不显示该轮播图</div>
        </el-tooltip>
        <el-checkbox
          v-model="dataForm.isShow"
          :disabled="disabled"
          placeholder="是否显示"
          >是否显示</el-checkbox
        >
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { fileMixin } from '../../../mixins';
export default {
  mixins: [fileMixin],
  data() {
    return {
      disabled: false,
      visible: false,
      freezerList: [],
      autoCropWidth: '270',
      autoCropHeight: '465',
      file: '',
      fileName: '',
      cropperVisible: false,
      dataForm: {
        id: '',
        freezerId: '',
        isShow: true,
        name: '',
        imageUrl: '',
      },
      dataRule: {
        freezerId: [
          {
            required: true,
            message: '冷柜不能为空',
            trigger: 'blur',
          },
        ],
        imageUrl: [
          {
            required: true,
            message: '轮播图片不能为空',
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: '轮播图名字不能为空',
            trigger: 'blur',
          },
        ],
        isShow: [
          {
            required: true,
            message: '是否显示不能为空',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.getFreezerList();
  },
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/cc/banner/id/query/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.dataForm = data.data;
            }
          });
        }
      });
    },
    fileUpload({ file }) {
      this.$upload({
        file,
        after: (data) => {
          this.dataForm.imageUrl = data.url;
          this.dataForm.name = file.name;
        },
      });
    },
    beforeUpload(file) {
      console.log(file.type);
      if (
        file.type !== 'image/jpg' &&
        file.type !== 'image/jpeg' &&
        file.type !== 'image/png' &&
        file.type !== 'image/gif' &&
        file.type !== 'video/mp4'
      ) {
        this.$message.error('只支持jpg、png、gif格式的图片/mp4格式的视频！');
        return false;
      }
    },
    avatarUpload(file) {
      const form = new FormData();
      form.append('file', file);
      form.append('fileName', this.fileName);
      this.$http({
        url: `/cc/oss/image/upload`,
        method: 'post',
        data: form,
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.dataForm.imageUrl = data.data.url;
        }
      });
    },
    changeUpload(file) {
      this.onConfirm();
      var img = file.name.substring(file.name.lastIndexOf('.') + 1);
      console.log(img);
      const suffix =
        img === 'jpg' || img === 'png' || img === 'jpeg' || img === 'mp4';
      if (!suffix) {
        this.$message.error('只能上传图片');
        return false;
      }
      const reader = new FileReader();
      reader.onload = () => {
        this.file =
          typeof reader.result === 'object'
            ? window.URL.createObjectURL(new Blob([reader.result]))
            : reader.result;
      };
      this.cropperVisible = true;
      reader.readAsArrayBuffer(file.raw);
      this.fileName = file.name;
    },
    async onConfirm(blob) {
      const form = new FormData();
      form.append('file', blob);
      this.$http({
        url: `/cc/oss/image/upload`,
        method: 'post',
        data: form,
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.dataForm.imageUrl = data.data.url;
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/cc/banner/${!this.dataForm.id ? 'save' : 'id/update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },

    //冷柜列表
    getFreezerList() {
      this.$http({
        url: `/cc/freezer/all`,
        method: 'get',
        params: { isUse: 1 },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.freezerList = data.data.items;
        } else {
          this.freezerList = [];
        }
      });
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  border-radius: 0 !important;
  display: block;
}
</style>
